import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import { TranslateService as Tolgee } from "@tolgee/ngx";

@Component({
    selector: 'app-status-change-dialog',
    templateUrl: './status-change-dialog.component.html',
    styleUrls: ['./status-change-dialog.component.scss']
})
export class StatusChangeDialogComponent implements OnInit {

    title = '';
    message = '';
    status = [];
    cancelButton = '';

    constructor(public dialogRef: MatDialogRef<StatusChangeDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data, public tolgee: Tolgee) {
        this.title = data.title;
        this.message = data.message;
        this.status = data.status;
        this.cancelButton = data.cancelButton;
        
    }

    ngOnInit(): void {
    }

    nextStatusNameAction(status): string {
        this.tolgee.instant("obligatory_fields_key") ?? ""
        switch (status) {
            case 'pending':
                return this.tolgee.instant("pending_key") ?? "";
            case 'open':
                return this.tolgee.instant("to_open_key") ?? "";
            case 'storage':
                return this.tolgee.instant("to_deposit_key") ?? "";
            case 'closed':
                return this.tolgee.instant("to_close_key") ?? "";
        }
    }

}
