import {Injectable} from '@angular/core';
import {BaseService} from '../../core/services/base.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {GeneralResponse} from '../models/general-response.model';
import {catchError, map} from 'rxjs/operators';

import {CountryModel} from '../models/country.model';

@Injectable({
    providedIn: 'root'
})
export class CountryService extends BaseService {


    constructor(public router: Router,
                public http: HttpClient,

    ) {
        super(router);

    }





    getAll(): Observable<{} | GeneralResponse> {

        const url = `${this._api}data/countries`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {
                    const data = CountryModel.createArray(res.data, new CountryModel());
                    return new GeneralResponse('', false, false, data);

                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );

    }








}