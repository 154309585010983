import {Component, Input, OnInit} from '@angular/core';
import {fuseAnimations} from '../../../../@fuse/animations';
import { TranslateService as Tolgee } from '@tolgee/ngx';

@Component({
    selector: 'app-card-count',
    templateUrl: './card-count.component.html',
    styleUrls: ['./card-count.component.scss'],
    animations: fuseAnimations
})
export class CardCountComponent implements OnInit {
    @Input() loading: boolean;
    @Input() title: string;
    @Input() detail: string;
    @Input() count: number;
    @Input() total: number;
    @Input() color: string;
    @Input() totalText: string;
    constructor( public tolgee: Tolgee,) {
        
    }

    ngOnInit(): void {

           // Check if the tolgee service was initialized. If not, start it with the config.
      if (this.tolgee.tolgee === undefined) {
        this.tolgee.start(this.tolgee['config']);}
        
    }

}
