export const locale = {
  lang: 'pt',
  data: {
    'CRT y MIC': 'CRT y MIC',
    'CRT': 'CRT',
    'Pedidos': 'Petições',
    'home':'home',
    'Usuarios':'Usuários',
    'Usuarios de clientes':'Usuários do cliente',
    'Camioneros':'Caminhoneiros',
    'Administradores':'Administradores',
    'Aduana':'Costumes',
    'Compañía':'Companhia',
    'Noticias':'Notícias',
    'Reportes':'Relatórios',
    'Reporte de Pedidos':'Relatório de pedido'


  }
}