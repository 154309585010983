<div class="page-layout blank p-24" fusePerfectScrollbar>

  <div fxLayout="row" class="margin-bottom" fxLayoutAlign="start">
    <app-card-count fxFlex="100" title={{internationalTitle}} detail={{internationalDetails}}
      [loading]="loadingInternationOrders" [count]="internationalOpenOrders" [total]="internationalOrders"
      totalText={{internationalTotal}} color="light-blue-fg"></app-card-count>

    <app-card-count fxFlex="100" title={{nationalTitle}} detail={{nationalDetails}} [loading]="loadingNationalOrders"
      [count]="nationalOpenOrders" [total]="nationalOrders" color="green-600-fg"
      totalText={{nationalTotal}}></app-card-count>

    <app-card-count fxFlex="100" title={{pendingTitle}} detail={{pendingDetails}} [loading]="loadingOrderStatus"
      [count]="ordersPendingCount" [total]="ordersCount" color="orange-fg" totalText={{pendingTotal}}></app-card-count>
  </div>


  <div fxLayout="row" class="margin-bottom" fxLayoutAlign="start">


    <app-card-count fxFlex="100" title={{openTitle}} detail={{openDetails}} [loading]="loadingOrderStatus"
      [count]="ordersOpenedCount" [total]="ordersCount" color="green-600-fg" totalText={{openTotal}}></app-card-count>

    <app-card-count fxFlex="100" title={{storeTitle}} detail={{storeDetails}} [loading]="loadingOrderStatus"
      [count]="ordersStorageCount" [total]="ordersCount" color="purple-fg" totalText={{storeTotal}}></app-card-count>

    <app-card-count fxFlex="100" title={{closeTitle}} detail={{closeDetails}} [loading]="loadingOrderStatus"
      [count]="ordersClosedCount" [total]="ordersCount" color="red-fg" totalText={{closeTotal}}></app-card-count>
  </div>
  <br>


  <div fxLayout="row" class="margin-bottom" fxLayoutAlign="start">
    <div fxLayout="column" fxFlex="100">
      <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxFlex="100" fxFlex.gt-xs="33"
        fxFlex.gt-md="33">


        <div class="fuse-widget-front accent" style="min-height: 135px">
          <div class="p-16">
            <div class="h2 text-center" *ngIf="hasOrders">{{ "images_pending_key" | translate:"¡Hay imagenes/documentos
              pendientes de aprobación!" }}</div>
            <div class="h2 text-center" *ngIf="!hasOrders">{{ "images_no_pending_key" | translate:"¡No Hay
              imagenes/documentos pendientes de aprobación!" }}</div>
          </div>
          <div *ngIf="loadingImgDoc" fxLayout="row" fxLayoutAlign="center center" class="mb-8">
            <mat-spinner color="accent" [diameter]="50"></mat-spinner>
          </div>
          <div *ngIf="!loadingImgDoc && hasOrders" class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="h3 pr-16">{{ "orders_key" | translate:"Pedidos" }}</div>
            <mat-divider></mat-divider>
          </div>

          <!--mat-list>
            <mat-list-item style="cursor: pointer"
                           *ngFor="let order of ordersImgDocNoApproved"
                           (click)="goToOrder(order.id)"> {{order.number}} </mat-list-item>
          </mat-list-->

          <table class="simple clickable" *ngIf="!loadingImgDoc && hasOrders">
            <!--thead>
              <tr>
                <th class="text-right">N° seguimiento</th>
                <th></th>
              </tr>
            </thead-->
            <tbody>
              <tr style="cursor: pointer" (click)="goToOrder(order.id)" *ngFor="let order of ordersImgDocNoApproved">
                <td>N° {{order.number}}</td>
                <td class="text-right">
                  <mat-icon aria-hidden="false" aria-label="Ver pedido">remove_red_eye</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="p-8 pt-0" fxLayout="column" fxLayoutAlign="end end" *ngIf="!loadingImgDoc && !hasOrders">
            <button mat-button (click)="goToPedido()">{{ "show_orders_key" | translate:"VER PEDIDOS" }}</button>
          </div>
        </div>
      </fuse-widget>
    </div>

    <div fxLayout="column" fxFlex="100">
      <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxFlex="100" fxFlex.gt-xs="33"
        fxFlex.gt-md="33">


        <div class="fuse-widget-front orange-bg fuse-white-fg" style="min-height: 135px">
          <div class="p-16">
            <div class="h2 text-center" *ngIf="hasTruckersDocumentExpired">{{ "trucker_document_expired_key" |
              translate:"¡Hay camioneros con documentación vencida!" }}</div>
            <div class="h2 text-center" *ngIf="!hasTruckersDocumentExpired">{{ "trucker_document_avalible_key" |
              translate:"¡Todos los camioneros tienen su documentación al día!" }}</div>
          </div>
          <div *ngIf="loadingDocumentExpired" fxLayout="row" fxLayoutAlign="center center" class="mb-8">
            <mat-spinner color="accent" [diameter]="50"></mat-spinner>
          </div>
          <div *ngIf="!loadingDocumentExpired && hasTruckersDocumentExpired" class="p-16" fxLayout="row"
            fxLayoutAlign="space-between center">
            <div class="h3 pr-16">{{ "truckers_key" | translate:"Camioneros" }}</div>
            <mat-divider></mat-divider>
          </div>

          <table class="simple clickable" *ngIf="!loadingDocumentExpired && hasTruckersDocumentExpired">
            <tbody>
              <tr style="cursor: pointer" (click)="goToTrucker(trucker.id)"
                *ngFor="let trucker of truckersDocumentExpired">
                <td>{{trucker.user.fullName}}</td>
                <td class="text-right">
                  <mat-icon aria-hidden="false" aria-label="Ver pedido">remove_red_eye</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="p-8 pt-0" fxLayout="column" fxLayoutAlign="end end"
            *ngIf="!loadingDocumentExpired && !hasTruckersDocumentExpired">
            <button mat-button (click)="gotoCamionero()">{{ "show_truckers_key" | translate:"VER CAMIONEROS" }}</button>
          </div>
        </div>
      </fuse-widget>
    </div>

    <div fxLayout="column" fxFlex="100" style="min-height: 135px">
      <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxFlex="100" fxFlex.gt-xs="33"
        fxFlex.gt-md="33" *ngIf="truckers && truckers.length > 0">
        <div class="fuse-widget-front primary">

          <div class="p-16">
            <div class="h1 text-center">{{ "trunck_message_part1_key" | translate:"¡Tenés" }} {{truckers.length}} {{
              "trunck_message_part2_key" | translate:"mensajes sin leer!" }}</div>
            <div class="h4 secondary-text text-center">Estos son los camioneros que se comunicaron:</div>
          </div>

          <div *ngIf="truckers.length > 0" class="p-16" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="h3 pr-16">{{ "truckers_key" | translate:"Camioneros" }}</div>
            <mat-divider></mat-divider>
          </div>

          <table class="simple clickable" *ngIf="truckers.length > 0">
            <tbody>
              <tr style="cursor: pointer" (click)="goToChat(t.trucker.id)" *ngFor="let t of truckers">
                <td>{{t.fullName}}</td>
                <td class="text-right">
                  <mat-icon aria-hidden="false" aria-label="Ver pedido">remove_red_eye</mat-icon>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
      </fuse-widget>

      <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxFlex="100" fxFlex.gt-xs="50"
        fxFlex.gt-md="50" *ngIf="truckers && truckers.length === 0">
        <div class="fuse-widget-front primary">
          <div *ngIf="loadingChat" fxLayout="row" fxLayoutAlign="center center" class="mb-8">
            <mat-spinner color="accent" [diameter]="50"></mat-spinner>
          </div>
          <div class="p-16" *ngIf="!loadingChat">
            <div class="h1 text-center">{{ "up_to_date_key" | translate:"¡Estás al día!" }}</div>
            <div class="h4 secondary-text text-center">{{ "no_message_new_key" | translate:"No hay mensajes nuevos" }}
            </div>
          </div>


          <div class="p-8 pt-0" fxLayout="row" fxLayoutAlign="end end" *ngIf="!loadingChat">
            <button mat-button (click)="gotoCamionero()">{{ "show_message_key" | translate:"VER MENSAJERÍA" }}</button>
          </div>

        </div>
      </fuse-widget>
    </div>


  </div>


</div>