import { Serializable } from "app/core/entities/serializable.model";
import { OrderLoadModel } from "app/main/orders/models/order-load.model";
import { Auth } from "app/shared/models/auth.model";
import { TruckerModel } from "app/shared/models/trucker.model";

export class CarrierCostModel extends Serializable {
    public id: number;
    public contractStatus: string;
    public paymentCondition:string;
    public coin: string;
    public cashAdvance: number;
    public totalCash: number;
    public dailyChange: number;
    public extraCashAdvance: number;
    public observations: string;

    public trucker: TruckerModel;
    public ordersLoad: OrderLoadModel[];

    newModel(data: any): CarrierCostModel {
        const model = new CarrierCostModel(data);
        if (data.trucker) {
            model.trucker = TruckerModel.createOne(data.trucker, new Auth());
        }

        if (data.orderLoad) {
            model.ordersLoad = OrderLoadModel.createArray(
                data.orderLoad,
                new OrderLoadModel()
            );
        }

        return model;
    }

    prepareForStore(): any {
        return {
            id: this.id ?? null,
            contract_status: this.contractStatus,
            payment_condition: this.paymentCondition,
            coin: this.coin,
            cash_advance: this.cashAdvance,
            total_cash: this.totalCash,
            daily_change: this.dailyChange,
            extra_cash_advance: this.extraCashAdvance,
            observations: this.observations,

            trucker_id: this.trucker != null ? this.trucker.id : '',
        };
    }
}
