import {Serializable} from '../../core/entities/serializable.model';


export class RolModel extends Serializable {

    public id: number;
    public name: string;
    public key: string;



    newModel(data: any): RolModel {
        return new RolModel(data);
    }

}
