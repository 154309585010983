import {Serializable} from '../../../core/entities/serializable.model';
import {OrderDocumentModel} from './order-document.model';
import {Auth} from '../../../shared/models/auth.model';
import {CountryModel} from '../../../shared/models/country.model';
import {UserModel} from '../../../shared/models/user.model';
import { OrderModel } from './order.model';
import { TruckerModel } from 'app/shared/models/trucker.model';
import { ContractModel } from 'app/main/contracts/models/contract.model';
import { OrderUnloadModel } from './order-unload';
import { CarrierCostModel } from 'app/main/contracts/models/carrier-cost.model';

export class OrderLoadModel extends Serializable {
    public id: number;
    public truckerName: string;
    public truckerDni: string;
    public truckerPlate: string;
    public orderStatus: string;
    public sender: string;
    public receiver: string;
    public manager: string;
    
    public paymentCondition: string;
    public coin: string;
    public cashAdvance: number;
    public totalCash: number;
    public dailyChange: number;

    public addressFrom: string;
    public latitudeFrom: number;
    public longitudeFrom: number;
    public addressTo: string;
    public latitudeTo: number;
    public longitudeTo: number;
    public distance: string;

    public crtNumber: string;
    public city: string;
    public state: string;
    public country: string;
    public orderNumber: string;

    public offer: string;
    public quantity: string;
    public weight: string;
    public volume: string;
    public observations: string;
    public orderLoadDate: string;

    public hasProblem: number;
    public problemDescription: string;
    

    public countryFrom: CountryModel;
    public countryTo: CountryModel;

    public trucker: TruckerModel;
    public order: OrderModel;
    public contract: ContractModel;
    public orderUnload: OrderUnloadModel;
    public carrierCost: CarrierCostModel;

    

    newModel(data: any): OrderLoadModel {
        const dataParsed = new OrderLoadModel(data);
        if (data.trucker) {
            dataParsed.trucker = TruckerModel.createOne(data.trucker, new Auth());
        }

        if (data.order) {
            dataParsed.order = OrderModel.createOne(data.order, new OrderModel());
        }

        if (data.contract) {
            dataParsed.contract = ContractModel.createOne(
                data.contract,
                new ContractModel()
            );
        }

        if (data.countryFrom) {
            dataParsed.countryFrom = CountryModel.createOne(
                data.countryFrom,
                new CountryModel()
            );
        }
        if (data.countryTo) {
            dataParsed.countryTo = CountryModel.createOne(
                data.countryTo,
                new CountryModel()
            );
        }

        if (data.orderUnload) {
            dataParsed.orderUnload = OrderUnloadModel.createOne(
                data.orderUnload,
                new OrderUnloadModel()
            );
        }
       
        if (data.carrierCost) {
            dataParsed.carrierCost = CarrierCostModel.createOne(
                data.carrierCost,
                new CarrierCostModel()
            );
        }

        return new OrderLoadModel(dataParsed);
    }

    get statusName(): string {
        switch (this.orderStatus) {
            case 'pending':
                return 'Pendiente';
            case 'open':
                return 'Activa';
            case 'closed':
                return 'Cerrada';
        }
    }

    get nextStatus(): string[] {
        switch (this.orderStatus) {
            case 'open':
                return ['closed'];
            case 'pending':
                return ['open'];
            case 'closed':
                return ['open'];
        }
    }

    get nextStatusColor(): string {
        switch (this.orderStatus) {
            case 'pending':
                return 'open';
            case 'open':
                return 'closed';
            case 'closed':
                return 'open';
        }
    }

    get nextStatusNameAction(): string {
        switch (this.orderStatus) {
            case 'pending':
                return 'Activar';
            case 'open':
                return 'Cerrar';
            case 'closed':
                return 'Activar';
        }
    }

    get nextStatusName(): string {
        switch (this.orderStatus) {
            case 'pending':
                return 'Activa';
            case 'open':
                return 'Cerrada';
            case 'closed':
                return 'Activa';
        }
    }

    prepareForStore(): any {
        let data = {
            id: this.id ?? null,
            receiver: this.receiver,
            manager: this.manager,
            sender: this.sender,
            order_status: this.orderStatus,
            trucker_name: this.truckerName,
            trucker_plate: this.truckerPlate,
            trucker_dni: this.truckerDni,
            trucker_id: this.trucker ? this.trucker.user ? this.trucker.user.id : this.trucker.id : null,
            order_id: this.order ? this.order.id : null,

            payment_condition: this.paymentCondition,
            coin: this.coin,
            cash_advance: this.cashAdvance,
            total_cash: this.totalCash,
            daily_change: this.dailyChange,

            address_from: this.addressFrom,
            country_from_id: this.countryFrom.id,
            latitude_from: this.latitudeFrom,
            longitude_from: this.longitudeFrom,
            address_to: this.addressTo,
            country_to_id: this.countryTo.id,
            latitude_to: this.latitudeTo,
            longitude_to: this.longitudeTo,
            distance: this.distance,

            crt_number: this.crtNumber,
            city: this.city,
            state: this.state,
            country: this.country,
            order_number: this.orderNumber,
            offer: this.offer,
            quantity: this.quantity,
            weight: this.weight,
            volume: this.volume,
            observations: this.observations,
            order_load_date: this.orderLoadDate,
            has_problem: this.hasProblem,
            problem_description: this.problemDescription,

            //users: this.users.map(u => u.id),
        };

        return data;
    }

    prepareForStoreIDs(): any {
        let data = {
            id: this.id ?? null,            
        };
        return data;
    }
}
