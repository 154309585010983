import {Injectable} from '@angular/core';
import {BaseService} from '../../core/services/base.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {GeneralResponse} from '../models/general-response.model';
import {catchError, map} from 'rxjs/operators';
import {TruckerModel} from '../models/trucker.model';
import {TrailerModel} from '../models/trailer.model';
import {ClientModel} from '../models/client.model';
import {UserModel} from '../models/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {

    constructor(public router: Router,
                public http: HttpClient,
    ) {
        super(router);
    }



    //
    // store(data: TruckerModel): Observable<{} | GeneralResponse> {
    //     const url = `${this._api}truckers`;
    //     return this.http.post<GeneralResponse>(url, data.prepareForStore())
    //         .pipe(
    //             map(res => {
    //                 return new GeneralResponse('', false, false, res.data);
    //             }),
    //             catchError(err => {
    //                 return this.handleError(err);
    //             })
    //         );
    // }

    getAll(rol: number, lang: number): Observable<{} | GeneralResponse> {
        const url = `${this._api}users-all?include=role,language&filter[role]=${rol}&filter[language]=${lang}`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {

                    const data = UserModel.createArray(res.data, new UserModel());
                    return new GeneralResponse('', false, false, data);

                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );

    }

    // getOne(truckerSelected: number): Observable<{} | GeneralResponse> {
    //
    //     const url = `${this._api}truckers/${truckerSelected}?include=user,company,truck,truck.trailer,country`;
    //     return this.http.get<GeneralResponse>(url)
    //         .pipe(
    //             map(res => {
    //                 return new GeneralResponse('', false, false, TruckerModel.createOne(res.data, new TruckerModel()));
    //             }),
    //             catchError(err => {
    //                 return this.handleError(err);
    //             })
    //         );
    // }
    //
    // update(trucker: TruckerModel): Observable<{} | GeneralResponse> {
    //     const url = `${this._api}truckers/${trucker.id}`;
    //     return this.http.put<GeneralResponse>(url, trucker.prepareForStore())
    //         .pipe(
    //             map(res => {
    //                 return new GeneralResponse('', false, false, TruckerModel.createOne(res.data, new TruckerModel()));
    //             }),
    //             catchError(err => {
    //                 return this.handleError(err);
    //             })
    //         );
    // }
    //
    // delete(trucker: TruckerModel): Observable<{} | GeneralResponse> {
    //     const url = `${this._api}truckers/${trucker.id}`;
    //     return this.http.delete<GeneralResponse>(url)
    //         .pipe(
    //             map(res => {
    //                 return new GeneralResponse('', false, false, res.data);
    //             }),
    //             catchError(err => {
    //                 return this.handleError(err);
    //             })
    //         );
    // }

    getAllChats(): Observable<{} | GeneralResponse> {
        const url = `${this._api}truckers-messages`;
        return this.http.get<GeneralResponse>(url)
            .pipe(
                map(res => {

                    const data = UserModel.createArray(res.data, new UserModel());
                    return new GeneralResponse('', false, false, data);

                }),
                catchError(err => {
                    return this.handleError(err);
                })
            );

    }


}
