import {NgModule} from '@angular/core';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {StatusChangeDialogComponent} from './status-change-dialog/status-change-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';

@NgModule({
    declarations: [
        ConfirmDialogComponent,
        StatusChangeDialogComponent,
        MessageDialogComponent

    ],
    imports: [
        CommonModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        FuseSharedModule
    ],

})
export class DialogsModule {}
