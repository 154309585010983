import {Serializable} from '../../../core/entities/serializable.model';
import { OrderImageModel } from './order-image.model';

export class OrderReceptionModel extends Serializable {
    public id: number;
    public orderId: number;
    public sender: string;
    public receiver: string;
    public manager: string;

    public crtNumber: string;
    public deposit: string;
    public orderNumber: string;

    public offer: string;
    public quantity: string;
    public weight: string;
    public volume: string;
    public observations: string;
    public orderReceptionDate: string;
    public hasProblem: number;
    public problemDescription: string;
    
    public orderImage: OrderImageModel[];
    
    newModel(data: any): OrderReceptionModel {
        const dataParsed = new OrderReceptionModel(data);

        if (data.orderImage) {
            dataParsed.orderImage = OrderImageModel.createArray(data.orderImage, new OrderImageModel());
        }

        return new OrderReceptionModel(dataParsed);
    }

    prepareForStore(): any {
        let data = {
            id: this.id ?? null,
            receiver: this.receiver,
            manager: this.manager,
            sender: this.sender,
            order_id: this.orderId,

            crt_number: this.crtNumber,
            deposit: this.deposit,
            order_number: this.orderNumber,
            offer: this.offer,
            quantity: this.quantity,
            weight: this.weight,
            volume: this.volume,
            observations: this.observations,
            order_reception_date: this.orderReceptionDate,
            has_problem: this.hasProblem,
        };

        if (this.orderImage != null && this.orderImage.length > 0) {
            var images = [];
            this.orderImage.forEach(image => {
                images.push(image.prepareForStoreOrderUnload());
            });
            data['images'] = images;
        } else {
            data['images'] = [];
        }

        data['problem_description'] = this.problemDescription;

        return data;
    }
}
