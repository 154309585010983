import {Serializable} from '../../core/entities/serializable.model';
import {UserModel} from './user.model';


export class ChatMessage extends Serializable {

    public id: number;
    public message: string;
    public createdAt: Date;
    public read: number;

    public userMessage: UserModel;





    newModel(data: any): ChatMessage {
        const c = new ChatMessage(data);
        if (data.userMessage) {
            c.userMessage = UserModel.createOne(data.userMessage, new UserModel());
        } else {
            c.userMessage = null;
        }

        return c;
    }





}