<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/transportia-icon.png" />
                </div>
            </div>

            <!-- <div class="px-8 px-md-16">
                <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
            </div> -->
        </div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16" src="assets/images/logos/icon-mod.png" />
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{
                        fullName
                        }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <div>
                    <mat-form-field fxFlex="100" class="ml-20 mr-20">
                        <mat-label>Idioma</mat-label>
                        <mat-select (selectionChange)="setLanguage($event.value)" [value]="languages[0]">
                            <mat-option *ngFor="let c of languages" [value]="c">
                                {{ c.title }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <button mat-menu-item class="" (click)="onLogout()" fxFlex="100">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Cerrar Sesión</span>
                </button>
            </mat-menu>

            <div class="toolbar-separator"></div>
        </div>
    </div>
</mat-toolbar>