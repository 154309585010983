import {Serializable} from "../../core/entities/serializable.model";
import {RolModel} from "./rol.model";
import {LanguageModel} from "./language.model";
import {ChatMessage} from "./chat-message.model";
import {TruckModel} from './truck.model';
import {TruckerModel} from './trucker.model';

export class UserModel extends Serializable {
    public id: number;
    public fullName: string;
    public name: string;
    public lastName: string;
    public email: string;
    public languageId: number;
    public password: string;
    public passwordConfirmation: string;
    public roleId: number;

    public role: RolModel;

    public language: LanguageModel;
    public selected = false;

    public sendNewOrderEmails: number;
    public sendChatEmails: number;
    public sendPhotoPendingEmails: number;
    public sendCrossoverEmails: number;
    public sendFollowUpEmails: number;
    public sendEndorsementEmails:number;
    public sendPhoneNotifications:number;

    public messages: ChatMessage[];
    public trucker: TruckerModel;

    newModel(data: any): UserModel {
        const dataParsed = new UserModel(data);
        if (data.messages) {
            data.messages = ChatMessage.createOne(
                data.messages,
                new ChatMessage()
            );
        } else {
            data.messages = null;
        }

        if (data.role) {
            dataParsed.role = RolModel.createOne(data.role, new RolModel());
        } else {
            dataParsed.role = null;
        }

        if (data.language) {
            dataParsed.language = LanguageModel.createOne(
                data.language,
                new LanguageModel()
            );
        } else {
            dataParsed.language = null;
        }

        if (data.trucker) {
            dataParsed.trucker = TruckerModel.createOne(
                data.trucker,
                new TruckerModel()
            );
        } else {
            dataParsed.trucker = null;
        }

        // return new UserModel(data);
        return dataParsed;
    }

    prepareForStore(): any {
        return {
            name: this.name,
            last_name: this.lastName,
            email: this.email,
            language_id: this.languageId,
            role_id: this.roleId,
            password: this.password,
            password_confirmation: this.passwordConfirmation,
            send_new_order_emails: this.sendNewOrderEmails,
            send_chat_emails: this.sendChatEmails,
            send_photo_pending_emails: this.sendPhotoPendingEmails,
            send_crossover_emails: this.sendCrossoverEmails,
            send_follow_up_emails: this.sendFollowUpEmails,
            send_endorsement_emails:this.sendEndorsementEmails,
            send_phone_notifications:this.sendPhoneNotifications
        };
    }

    prepareForUpdate(): any {
        const data = {
            name: this.name,
            last_name: this.lastName,
            email: this.email,
            language_id: this.languageId,
            role_id: this.roleId,
            send_new_order_emails: this.sendNewOrderEmails,
            send_chat_emails: this.sendChatEmails,
            send_photo_pending_emails: this.sendPhotoPendingEmails,
            send_crossover_emails: this.sendCrossoverEmails,
            send_follow_up_emails: this.sendFollowUpEmails,
            send_endorsement_emails:this.sendEndorsementEmails,
            send_phone_notifications:this.sendPhoneNotifications
        };
        if (this.password) {
            data['password'] = this.password;
            data['password_confirmation'] = this.passwordConfirmation;
        }
        return data;
    }
}
