import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {GeneralResponse} from '../../shared/models/general-response.model';
import {UserService} from '../../shared/services/user.service';
import {BaseComponent} from '../../core/components/base-component';
import {OrderService} from '../orders/order.service';
import {fuseAnimations} from '../../../@fuse/animations';
import {OrderModel} from '../orders/models/order.model';
import {TruckModel} from '../../shared/models/truck.model';
import {Auth} from '../../shared/models/auth.model';
import {UserModel} from '../../shared/models/user.model';
import {TruckerService} from '../../shared/services/trucker.service';
import {TruckerModel} from '../../shared/models/trucker.model';
import { TranslateService as Tolgee } from '@tolgee/ngx';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: fuseAnimations
})
export class HomeComponent extends BaseComponent implements OnInit {

    internationalOrders: number;
    truckers: UserModel [] = [];
    internationalOpenOrders: number;

    nationalOpenOrders: number;
    nationalOrders: number;

    ordersImgDocNoApproved: OrderModel[] = [];

    truckersDocumentExpired: TruckerModel[] = [];

    ordersOpenedCount: number;
    ordersPendingCount: number;
    ordersClosedCount: number;
    ordersStorageCount: number;
    ordersCount: number;

    loadingInternationOrders: boolean;
    loadingNationalOrders: boolean;
    loadingChat: boolean;
    loadingImgDoc: boolean;
    loadingOrderStatus: boolean;
    loadingDocumentExpired: boolean;
    internationalTitle=this.tolgee.instant('internationals_orders_key')??'';
    internationalDetails=this.tolgee.instant('international_details_key')??'';
    internationalTotal=this.tolgee.instant('international_total_key')??'';

    nationalTitle=this.tolgee.instant('national_ordes_key')??'';
    nationalDetails=this.tolgee.instant('national_details_key')??'';
    nationalTotal=this.tolgee.instant('national_total_key')??'';

    pendingTitle=this.tolgee.instant('order_pending_details_key')??'';
    pendingDetails=this.tolgee.instant('order_pending_details_key')??'';
    pendingTotal=this.tolgee.instant('order_pending_total_key')??'';

    openTitle=this.tolgee.instant('order_open_title_key')??'';
    openDetails=this.tolgee.instant('order_open_details_key')??'';
    openTotal=this.tolgee.instant('order_open_total_key')??'';

    storeTitle=this.tolgee.instant('order_store_title_key')??'';
    storeDetails=this.tolgee.instant('order_store_details_key')??'';
    storeTotal=this.tolgee.instant('order_store_total_key')??'';

    closeTitle=this.tolgee.instant('order_close_title_key')??'';
    closeDetails=this.tolgee.instant('order_close_details_key')??'';
    closeTotal=this.tolgee.instant('order_close_total_key')??'';
    

    constructor(private router: Router,
                private userService: UserService,
                private orderService: OrderService,
                private truckerService: TruckerService,
                public tolgee: Tolgee,
                ) {
        super();
    }


    ngOnInit(): void {
        this.getChats();
        this.getInternational();
        this.getNational();
        this.getOrdersImgDocNoApproved();
        this.getOrderStatus();
        this.getTruckesWithDocumentExpired();

             // Check if the tolgee service was initialized. If not, start it with the config.
      if (this.tolgee.tolgee === undefined) {
        this.tolgee.start(this.tolgee['config']);}
    }

    gotoCamionero(): void {
        this.router.navigate(['', 'user-list', 'trackers']);
    }

    getChats(): void {
        this.loadingChat = true;
        this.userService.getAllChats()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (response: GeneralResponse) => {
                    if (response.error) {
                        this.loadingChat = false;
                        return;
                    }
                    this.truckers = response.data;
                    this.loadingChat = false;

                },
                (response: GeneralResponse) => {
                    this.loadingChat = false;
                });
    }


    goToPedido(): void {
        this.router.navigate(['', 'orders']);
    }


    getInternational(): void {
        this.loadingInternationOrders = true;
        this.orderService.getInternationalCount()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (response: GeneralResponse) => {
                    if (response.error) {
                        this.loadingInternationOrders = false;
                        return;
                    }
                    this.internationalOrders = response.data.international_order_cant;
                    this.internationalOpenOrders = response.data.internation_open_orders_cant;
                    this.loadingInternationOrders = false;

                },
                (response: GeneralResponse) => {
                    this.loadingInternationOrders = false;
                });
    }

    getNational(): void {
        this.loadingNationalOrders = true;
        this.orderService.getNationalCount()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (response: GeneralResponse) => {
                    if (response.error) {
                        this.loadingNationalOrders = false;
                        return;
                    }
                    this.nationalOrders = response.data.national_order_cant;
                    this.nationalOpenOrders = response.data.nation_open_orders_cant;
                    this.loadingNationalOrders = false;

                },
                (response: GeneralResponse) => {
                    this.loadingNationalOrders = false;
                });
    }

    getOrderStatus(): void {
        this.loadingOrderStatus = true;
        this.orderService.getOrdersCountByStatus()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (response: GeneralResponse) => {
                    if (response.error) {
                        this.loadingOrderStatus = false;
                        return;
                    }
                    this.ordersCount = response.data.orders;
                    this.ordersOpenedCount = response.data.open_orders;
                    this.ordersPendingCount = response.data.pending_orders;
                    this.ordersClosedCount = response.data.closed_orders;
                    this.ordersStorageCount = response.data.storage_orders;
                    this.loadingOrderStatus = false;

                },
                (response: GeneralResponse) => {
                    this.loadingOrderStatus = false;
                });
    }

    getOrdersImgDocNoApproved(): void {
        this.loadingImgDoc = true;
        this.orderService.getOrdersImgDocNoApproved()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (response: GeneralResponse) => {
                    if (response.error) {
                        this.loadingImgDoc = false;
                        return;
                    }
                    this.ordersImgDocNoApproved = response.data;
                    this.loadingImgDoc = false;

                },
                (response: GeneralResponse) => {
                    this.loadingImgDoc = false;
                });
    }

    getTruckesWithDocumentExpired(): void {
        this.loadingDocumentExpired = true;
        this.truckerService.getTruckesWithDocumentExpired()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(
                (response: GeneralResponse) => {
                    if (response.error) {
                        this.loadingDocumentExpired = false;
                        return;
                    }
                    this.truckersDocumentExpired = response.data;
                    this.loadingDocumentExpired = false;

                },
                (response: GeneralResponse) => {
                    this.loadingDocumentExpired = false;
                });
    }


    get hasOrders(): boolean {
        return this.ordersImgDocNoApproved.length > 0;
    }

    get hasTruckersDocumentExpired(): boolean {
        return this.truckersDocumentExpired.length > 0;
    }
    goToOrder(id: number): void {
        this.router.navigate([`orders/detail/${id}`]);
    }

    goToChat(id: number): void {
        this.router.navigate([`messages/${id}`]);
    }

    goToTrucker(id: number): void {
        this.router.navigate([`user-list/trackers/${id}`]);
    }
}
