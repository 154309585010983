import {Serializable} from '../../core/entities/serializable.model';
import { CustomPermitModel } from './custom-permit.model';
import { CountryModel } from './country.model';

export class BranchModel extends Serializable {
    public id: number;
    public enterprise_id: number;
    public name: string;
    public cuit: string;
    public address: string;
    public city: string;
    public state: string;
    public country_id: string;
    public phone: string;
    public email: string;
    public coin: string;
    public customPermits: CustomPermitModel[];
    public country:CountryModel;

    newModel(data: any): BranchModel {
        const dataParsed = new BranchModel(data);
        return dataParsed;
    }

    prepareForStore(): any {
        return {
            enterprise_id: this.enterprise_id,
            name: this.name,
            cuit: this.cuit,
            address: this.address,
            city: this.city,
            state: this.state,
            country_id: this.country_id,
            phone: this.phone,
            email: this.email,
            coin: this.coin
        };
    }
}
