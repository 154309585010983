import {Serializable} from '../../core/entities/serializable.model';

export class CompanyTypeModel extends Serializable {
    public id: number;
    public key: string;
    public verbose: string;

    newModel(data: any): CompanyTypeModel {
        return new CompanyTypeModel(data);
    }
}
