import {Serializable} from '../../../core/entities/serializable.model';
import {OrderTypeStageModel} from './order-type-stage.model';
import * as moment from 'moment';
import {UserModel} from "../../../shared/models/user.model";

export class OrderFollowUpModel extends Serializable {
    public id: number;
    public date: Date;
    public details: string;
    public orderTypeStage: OrderTypeStageModel;
    public user: UserModel;

    static createEmpty(): OrderFollowUpModel {
        const followUp = new OrderFollowUpModel();
        followUp.date = null;
        followUp.details = '';
        followUp.orderTypeStage = null;
        return followUp;
    }

    newModel(data: any): OrderFollowUpModel {
        const dataParsed = new OrderFollowUpModel(data);
        if (data.orderTypeStage) {
            dataParsed.orderTypeStage = OrderTypeStageModel.createOne(data.orderTypeStage, new OrderTypeStageModel());
        }
        return new OrderFollowUpModel(dataParsed);
    }

    get time(): any {
        return this.date ? moment(this.date).format('HH:mm') : null;
    }

    prepareForStore(): any {
        return {
            order_type_stage_id: this.orderTypeStage.id,
            date: this.date,
            details: this.details
        };
    }
}
