import { Serializable } from "app/core/entities/serializable.model";
import { OrderModel } from "./order.model";

export class OrderLocationModel extends Serializable {
    public longitude: string;
    public latitude: string;
    public percentage: number;
    public created: string;
    public order: OrderModel;

    newModel($data: any): OrderLocationModel {
        return new OrderLocationModel($data);
    }
}
