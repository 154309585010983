import { Serializable } from "app/core/entities/serializable.model";


export class CrtMicPermitModel extends Serializable {
    public id: number;
    public name: string;
    public codePrefix: string;
    public cuit: string;
    public cityCountry: string;
    public address: string;
    public licenseNorm: string;    
    public originalLicense: string;
    public originalDate: string;
    public complementaryLicense: string;
    public complementaryDate: string;
    public insurancePolicy: string;   
    public insuranceDate: string;   


    newModel(data: any): CrtMicPermitModel {
        return new CrtMicPermitModel(data);
    }

    prepareForStore(): any {
        return {
            id: this.id ?? null,
            name: this.name,
            cuit: this.cuit,
            code_prefix: this.codePrefix,
            city_country: this.cityCountry,
            address: this.address,
            license_norm: this.licenseNorm,
            original_license: this.originalLicense,
            original_date: this.originalDate,
            complementary_license: this.complementaryLicense,
            complementary_date: this.complementaryDate,
            insurance_policy: this.insurancePolicy,
            insurance_date: this.insuranceDate
        };
    }

    static createEmpty(): CrtMicPermitModel {
        const permit = new CrtMicPermitModel();
        permit.name = "";
        permit.codePrefix = "";
        permit.cuit = "";
        permit.cityCountry = "";
        permit.address = "";
        permit.licenseNorm = "";
        permit.originalLicense = "";
        permit.originalDate = "";
        permit.complementaryLicense = "";
        permit.complementaryDate = "";
        permit.insurancePolicy = "";
        permit.insuranceDate = "";
        return permit;
    }
}
