import {Serializable} from '../../core/entities/serializable.model';
import {CompanyTypeModel} from './company-type.model';
import { TariffModel } from './tariff.model';

export class CompanyModel extends Serializable {
    public id: number;
    public name: string;
    public companyRoutes:string;
    public cuit: string;
    public address: string;
    public cep: string;
    public city: string;
    public country_id: string;
    public phone: string;
    public email: string;
    public company_types_id: string;
    public notificationEmails: string;
    public crossoverEmails: string;
    public newLoadRemovalEmails: string;
    public seller:string;
    public companyType: CompanyTypeModel;
    public priceLists:TariffModel[];

    newModel(data: any): CompanyModel {
        const dataParsed = new CompanyModel(data);
        if (data.companyType) {
            this.companyType = CompanyTypeModel.createOne(data.companyType, new CompanyTypeModel());
        }
        if (data.priceLists) {
            this.priceLists = TariffModel.createArray(data.priceLists, new TariffModel());
        }
        return dataParsed;
    }

    prepareForStore(): any {
        console.log(this.companyRoutes);
        return {
            name: this.name,
            cuit: this.cuit,
            address: this.address,
            city: this.city,
            cep: this.cep,
            country_id: this.country_id,
            notification_emails: this.notificationEmails,
            crossover_emails: this.crossoverEmails,
            new_load_removal_emails: this.newLoadRemovalEmails,
            phone: this.phone,
            email: this.email,
            seller:this.seller,
            company_types_id: this.companyType.id,
            company_routes:this.companyRoutes,
        };
    }
}
