import * as moment from 'moment';

// function for dynamic sorting
export class HelperFunctions {
    /**
     * ordena un array de objetos
     * @param key campo a ordenar
     * @param order order
     */
    static compareValues(key, order = 'asc'): any {
        return function(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                return 0;
            }

            const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
            const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return order === 'desc' ? comparison * -1 : comparison;
        };
    }

    static sortDate(key, order = 'asc'): any {
        return (a, b) => {
            a = new Date(a.date);
            b = new Date(b.date);
            return order === 'asc' ? b - a : a - b;
        };
    }

    /**
     * Se pase tiempo desde y hasta hhmm y hhmm
     */
    static formatContactTime(from: string, until: string): string {
        return `entre las ${from.slice(0, 2)}:${from.slice(
            2
        )}hs y las ${until.slice(0, 2)}:${until.slice(2)}hs.`;
    }

    static downloadBlobFile(blob: Blob, filename: string): any {
        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.target = '_blank';
        link.click();
        setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    static convertBaseb64ToBlob(b64Data, contentType): Blob {
        contentType = contentType || '';
        const sliceSize = 512;
        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: contentType});
    }

    static getDateFormat(value, formatInit, formatReturn): any {
        if (formatInit) {
            return moment(value, formatInit).format(formatReturn);
        }
        return moment(value).format(formatReturn);
    }
}
