import {Serializable} from '../../../core/entities/serializable.model';

export class OrderTypeModel extends Serializable {
    public id: number;
    public name: string;
    public tag: string;

    newModel(data: any): OrderTypeModel {
        return new OrderTypeModel(data);
    }

    get isInternational(): boolean {
        return this.tag === 'internacional';
    }

    get isNational(): boolean {
        return this.tag === 'nacional';
    }
}
