import { Serializable } from "app/core/entities/serializable.model";

export class TariffModel extends Serializable {
    public id:number;
    public route: string;
    public transportCost: string;
    public secure: string;
    public totalCost: string;
    public paymentMethod: string;
    public coin: string;
    public responsibleForPayment: string;
    public detail: string;
    public checked:boolean;

    newModel(data: any): TariffModel {
        return new TariffModel(data);
    }

    prepareForStore(): any {
        return {
            route: this.route,
            transport_cost: this.transportCost,
            secure: this.secure,
            total_cost: this.totalCost,
            payment_method: this.paymentMethod,
            coin: this.coin,
            responsible_for_payment: this.responsibleForPayment,
            detail: this.detail,
        };
    }
}
