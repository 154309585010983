import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.loggedIn() && this.authService.currentAuth.role === 'admin') {
            return true;
        } else {
            this.router.navigate(['/login']);
        }
        return false;
    }

    canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.loggedIn();
        return false;
    }
}
