import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    title = '';
    message = '';
    okButton = '';
    cancelButton = '';

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) data) {
        this.title = data.title;
        this.message = data.message;
        this.okButton = data.okButton;
        this.cancelButton = data.cancelButton;
    }

    ngOnInit(): void {
    }

}
