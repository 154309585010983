import {Serializable} from '../../../core/entities/serializable.model';

export class OrderImageModel extends Serializable {
    public id: number;
    public description: string;
    public imageName: string;
    public orderId: number;
    public approved: number;
    public userApprovedId: string;
    public url: string;
    public file: any;
    public typeFile: string;
    public orderUnloadId:number;

    newModel(data: any): OrderImageModel {
        return new OrderImageModel(data);
    }

    prepareForStore(): any {
        const data = {
            images: []
        };
        // fixme por si mas adelante quiren subir mas archivos
        data.images.push({
            file: this.file,
            description: this.description,
            type_file: this.typeFile
        });
        return data;
    }

    prepareForStoreOrderUnload(): any {
        return {
            file: this.file,
            description: this.description,
            type_file: this.typeFile
        };
    }
}
