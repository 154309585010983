import { ReportModel } from './shared/models/report.model';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
// import { SampleModule } from 'app/main/sample/sample.module';
import { MaterialModule } from './shared/material.module';
import { HomeModule } from './main/home/home.module';
// import { AuthModule } from './auth/auth.module';
// import { UsersModule } from './main/users/users.module';
// import { UsersRoutingModule } from './main/users/users-routing.module';
import { DialogsModule } from './shared/dialogs/dialogs.module';
// import { OrdersModule } from './main/orders/orders.module';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatListModule } from '@angular/material/list';
// import { ReportsModule } from './main/reports/reports.module';
// import { NewsModule } from './main/news/news.module';
// import { CompaniesModule } from './main/companies/companies.module';
// import { MessagesModule } from './main/messages/messages.module';
import { AgmCoreModule } from '@agm/core';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';

import { NgxTolgeeModule } from '@tolgee/ngx';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatTableModule } from '@angular/material/table';


const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'user-list',
        loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule)
    },
    {
        path: 'customs',
        loadChildren: () => import('./main/customs/customs.module').then(m => m.CustomsModule)
    },
    {
        path: 'companies',
        loadChildren: () => import('./main/companies/companies.module').then(m => m.CompaniesModule)
    },
    {
        path: 'news',
        loadChildren: () => import('./main/news/news.module').then(m => m.NewsModule)
    },
   
    {
        path: 'messages',
        loadChildren: () => import('./main/messages/messages.module').then(m => m.MessagesModule)
    },
    {
        path: 'report',
        loadChildren: () => import('./main/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'orders',
        loadChildren: () => import('./main/orders/orders.module').then(m => m.OrdersModule)
    },
    {
        path: 'documents',
        loadChildren: () => import('./main/documents/documents.module').then(m => m.DocumentssModule)
    },
    {
        path: 'branch-offices',
        loadChildren: () => import('./main/branch-offices/branch-offices.module').then(m => m.BranchOfficesModule)
    },
    {
        path: 'permits',
        loadChildren: () => import('./main/permits/permits.module').then(m => m.PermitsModule)
    },
    {
        path: 'contract',
        loadChildren: () => import('./main/contracts/contracts.module').then(m => m.ContractsModule)
    },
    {
        path: 'export-import',
        loadChildren: () => import('./main/export-import/export-import.module').then(m => m.ExportImportModule)
    },
    {
        path: 'tariff',
        loadChildren: () => import('./main/tariff/tariff.module').then(m => m.TariffModule)
    },
];

@NgModule({
    declarations: [
        AppComponent,
        
        
       
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyBEVYrTVSGfEHUEqsuFM_rDGnbBBamRWmI",
            libraries: ["places"]
        }),

        NgxTolgeeModule.forRoot({
            staticData: {
                es: () => import("../assets/i18n/es.json"),
                pt: () => import("../assets/i18n/pt.json"),
            },
            apiUrl: 'https://app.tolgee.io',
            apiKey: 'tgpak_ge2tiok7mfsg2zdtofwwe3bugjyw65lgni2hezdogbwgc4btha',
            ui: 'tgpak_ge2tiok7mfsg2zdtofwwe3bugjyw65lgni2hezdogbwgc4btha' ? require('@tolgee/ui') : undefined
          }),         
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MaterialModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        MatSlideToggleModule,

        // App modules
        LayoutModule,
       // SampleModule,
        HomeModule,
       // AuthModule,
        //UsersModule,
        DialogsModule,
        //CompaniesModule,
       // OrdersModule,
       // NewsModule,
        MatToolbarModule,
       // MessagesModule,
       // ReportsModule,
        ReactiveFormsModule,
        
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: "es-AR" },
        DatePipe
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
