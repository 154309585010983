import {Serializable} from '../../../core/entities/serializable.model';
import {OrderTypeModel} from './order-type.model';

export class OrderTypeStageModel extends Serializable {
    public id: string;
    public name: string;
    public description: string;
    public orderType: OrderTypeModel;
    public tag: string;

    newModel(data: any): OrderTypeStageModel {
        const dataParsed = new OrderTypeStageModel(data);
        if (data.orderType) {
            dataParsed.orderType = OrderTypeModel.createOne(data.orderType, new OrderTypeModel());
        }
        return new OrderTypeStageModel(dataParsed);
    }
}
