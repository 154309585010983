import {Serializable} from '../../core/entities/serializable.model';
import {TruckModel} from './truck.model';
import {TrailerModel} from './trailer.model';
import {ShortUserModel as UserModel} from './shortUser.model';
import {CompanyModel} from './company.model';
import {CountryModel} from './country.model';

export class TruckerModel extends Serializable {
    public id: number;
    public company_id: number;
    public cuit: string;
    public dni: string;
    public licenceDueDate: string;
    public licencePictureFront: string;
    public licencePictureBack: string;
    public fatherName: number;
    public motherName: string;
    public address: string;
    public city: string;
    public countryId: string;
    public contactPhone: string;
    public arPhone: string;
    public brPhone: string;
    public complementaryDueDate: Date;
    public complementaryLicenceNumber: string;
    public licencePaut: string;
    public licenceNumber: string;

    public mic: any;

    public user: UserModel;
    public truck: TruckModel;
    public trailer: TrailerModel;
    public company: CompanyModel;
    public country: CountryModel;
    public trucker: TruckerModel;

    public newMsj: any;

    newModel(data: any): TruckerModel {
        const t = new TruckerModel(data);

        t.user = UserModel.createOne(data.user, new UserModel());
        if (data.truck) {
            t.truck = TruckModel.createOne(data.truck, new TruckModel());

            if (data.truck.trailer) {
                t.truck.trailer = TrailerModel.createOne(
                    data.truck.trailer,
                    new TrailerModel()
                );
            } else {
                t.truck.trailer = null;
            }
            
        } else {
            t.truck = null;
        }
        if (data.company) {
            t.company = CompanyModel.createOne(
                data.company,
                new CompanyModel()
            );
        } else {
            t.company = null;
        }
        if (data.country) {
            t.country = CountryModel.createOne(
                data.country,
                new CountryModel()
            );
        } else {
            t.country = null;
        }
        return t;
    }

    prepareForUpdate(): any {
        const data = {
            company_id: this.company_id,
            cuit: this.cuit,
            dni: this.dni,
            licence_due_date: this.licenceDueDate,
            licence_picture_front_file: this.licencePictureFront,
            licence_picture_back_file: this.licencePictureBack,
            complementary_due_date: this.complementaryDueDate,
            complementary_licence_number: this.complementaryLicenceNumber,
            licence_paut: this.licencePaut,
            licence_number: this.licenceNumber,
            father_name: this.fatherName,
            mother_name: this.motherName,
            address: this.address,
            city: this.city,
            country_id: this.countryId,
            contact_phone: this.contactPhone,
            ar_phone: this.arPhone,
            br_phone: this.brPhone,

            users: {
                name: this.user.name,
                last_name: this.user.lastName,
                email: this.user.email,
                language_id: this.user.languageId,
                // password: this.user.password,
                // password_confirmation: this.user.passwordConfirmation
            },

            truck: {
                plate_number: this.truck.plateNumber,
                tara: this.truck.tara,
                brand: this.truck.brand,
                title_file_base64: this.truck.titleFile,
                year: this.truck.year,
                color: this.truck.color,
                insurance_file_base64: this.truck.insuranceFile,
                insurance: this.truck.insurance,
                insurance_number: this.truck.insuranceNumber,
                insurance_due_date: this.truck.insuranceDueDate,
                technique_due_date: this.truck.techniqueDueDate,
                chassis_number: this.truck.chassisNumber
            },

            trailer: {
                plate_number: this.trailer.plateNumber,
                tara: this.trailer.tara,
                title_file_base64: this.trailer.titleFile,
                brand: this.trailer.brand,
                year: this.trailer.year,
                color: this.trailer.color,
                insurance_file_base64: this.trailer.insuranceFile,
                technique_due_date: this.trailer.techniqueDueDate
            }
        };

        if (this.user.password) {
            data.users['password'] = this.user.password;
            data.users['password_confirmation'] = this.user.passwordConfirmation;
        }

        return data;
    }

    prepareForStore(): any {
        return {
            company_id: this.company_id,
            cuit: this.cuit,
            dni: this.dni,
            licence_due_date: this.licenceDueDate,
            licence_picture_front_file: this.licencePictureFront,
            licence_picture_back_file: this.licencePictureBack,
            father_name: this.fatherName,
            mother_name: this.motherName,
            address: this.address,
            city: this.city,
            country_id: this.countryId,
            contact_phone: this.contactPhone,
            ar_phone: this.arPhone,
            br_phone: this.brPhone,
            complementary_due_date: this.complementaryDueDate,
            complementary_licence_number: this.complementaryLicenceNumber,
            licence_paut: this.licencePaut,
            licence_number: this.licenceNumber,

            user: {
                name: this.user.name,
                last_name: this.user.lastName,
                email: this.user.email,
                language_id: this.user.languageId,
                password: this.user.password,
                password_confirmation: this.user.passwordConfirmation
            },

            truck: {
                plate_number: this.truck.plateNumber,
                tara: this.truck.tara,
                brand: this.truck.brand,
                title_file_base64: this.truck.titleFile,
                year: this.truck.year,
                color: this.truck.color,
                insurance_file_base64: this.truck.insuranceFile,
                insurance: this.truck.insurance,
                insurance_number: this.truck.insuranceNumber,
                insurance_due_date: this.truck.insuranceDueDate,
                technique_due_date: this.truck.techniqueDueDate,
                chassis_number: this.truck.chassisNumber
            },

            trailer: {
                plate_number: this.trailer.plateNumber,
                tara: this.trailer.tara,
                title_file_base64: this.trailer.titleFile,
                brand: this.trailer.brand,
                year: this.trailer.year,
                color: this.trailer.color,
                insurance_file_base64: this.trailer.insuranceFile,
                technique_due_date: this.trailer.techniqueDueDate
            }
        };
    }

    
    static createEmpty(): TruckerModel {
        const trucker = new TruckerModel();
        trucker.company_id = null;
        trucker.cuit = "";
        trucker.dni = "";
        trucker.licenceDueDate = "";
        trucker.licencePictureFront = "";
        trucker.licencePictureBack = "";
        trucker.fatherName = null;
        trucker.motherName = "";
        trucker.address = "";
        trucker.city = "";
        trucker.countryId = "";
        trucker.contactPhone = "";
        trucker.arPhone = "";
        trucker.brPhone = "";
        trucker.complementaryDueDate = null;
        trucker.complementaryLicenceNumber = "";
        trucker.licencePaut = "";
        trucker.licenceNumber = "";
    
        trucker.mic = null;
    
        trucker.user = new UserModel();
        trucker.truck = new TruckModel();
        trucker.truck.trailer = new TrailerModel();
        trucker.trailer = new TrailerModel();
        trucker.company = new CompanyModel();
        trucker.country = new CountryModel();

        return trucker;
    }
}
