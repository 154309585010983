import { Serializable } from "app/core/entities/serializable.model";

export class CrtExpenseModel extends Serializable {
    public id: number;
    public crt_id: number;
    public description: string;
    public type: string;
    public sender_amount: string;
    public currency: string;
    public receiver_amount: string;
    public sender_usd: string;
    public receiver_usd: string;

    newModel(data: any): CrtExpenseModel {
        return new CrtExpenseModel(data);
    }

    prepareForStore(): any {
        return {
            id: this.id ?? null,
            crt_id: this.crt_id,
            description: this.description,
            type: this.type,
            sender_amount: this.sender_amount,
            currency: this.currency,            
            receiver_amount: this.receiver_amount,
            sender_usd: this.sender_usd,            
            receiver_usd: this.receiver_usd,
        };
    }
}
