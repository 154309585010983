import {Serializable} from '../../../core/entities/serializable.model';


export class CargoTypeModel extends Serializable {
    public id: number;
    public name: string;
    public tag: string;

    newModel(data: any): CargoTypeModel {
        return new CargoTypeModel(data);
    }
}
