import {Serializable} from '../../../core/entities/serializable.model';
import {OrderTypeStageModel} from './order-type-stage.model';
import {UserModel} from '../../../shared/models/user.model';

export class OrderLocationLogModel extends Serializable {
    public latitude: string;
    public longitude: string;
    public percentage: string;
    public previousPercentage: string;
    public error: string;
    public errorMessage: string;
    public processed: string;
    public forced: string;
    public created: string;
    public user: UserModel;

    newModel(data: any): OrderLocationLogModel {
        const dataParsed = new OrderLocationLogModel(data);
        if (data.user) {
            dataParsed.user = UserModel.createOne(data.user, new UserModel());
        }
        return dataParsed;
    }

}