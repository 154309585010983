import { FuseNavigation } from '@fuse/types';




export const navigation: FuseNavigation[] = [
    {
        id: 'home',
        title: 'home',
        translate: 'Home',
        type: 'item',
        icon: 'home',
        url: '/home',
    },

    {
        id: 'users',
        title: 'Usuarios',
        translate: 'Usuarios',
        type: 'group',
        icon: 'person_outline',
        children: [
            {
                id: 'users',
                title: 'Usuarios de clientes',
                translate: 'Usuarios de clientes',
                type: 'item',
                icon: 'person_outline',
                url: 'user-list/clients',
            },
            {
                id: 'trackers',
                title: 'Camioneros',
                translate: 'Camioneros',
                type: 'item',
                icon: 'local_shipping',
                url: 'user-list/trackers',
            },

            {
                id: 'admins',
                title: 'Administradores',
                translate: 'Administradores',
                type: 'item',
                icon: 'assignment_ind',
                url: 'user-list/admin',
            },
        ],
    },

    {
        id: 'order',
        title: 'Pedidos',
        translate: 'Pedidos',
        type: 'group',
        children: [
            {
                id: 'orders',
                title: 'Pedidos',
                translate: 'Pedidos',
                type: 'item',
                icon: 'business',
                url: '/orders',
            },
        ],
    },

    {
        id: 'crt_mic',
        title: 'CRT y MIC',
        translate: 'CRT y MIC',
        type: 'group',
        children: [
            {
                id: 'crts',
                title: 'CRT',
                translate: 'CRT',
                type: 'item',
                icon: 'list_alt',
                url: 'documents/crts'
            },
            {
                id: 'mics',
                title: 'MIC',
                translate: 'MIC',
                type: 'item',
                icon: 'list_alt',
                url: 'documents/mics'
            },
        ],
    },
    {
        id: 'contracts',
        title: 'Contratos',
        translate: 'Contratos',
        type: 'group',
        children: [
            {
                id: 'contract',
                title: 'Contrato',
                translate: 'Contrato',
                type: 'item',
                icon: 'list_alt',
                url: 'contract/contracts'
            },
            {
                id: 'contract_transporter',
                title: 'Contratantes',
                translate: 'Contratantes',
                type: 'item',
                icon: 'business',
                url: 'contract/contract-transporters'
            },
            {
                id: 'carrier_cost',
                title: 'Costos de Flete',
                translate: 'Costos de Flete',
                type: 'item',
                icon: 'local_shipping',
                url: 'contract/carrier-cost'
            },
        ],
    },
    {
        id: 'abm',
        title: 'ABMs',
        translate: 'ABMs',
        type: 'group',
        children: [
            {
                id: 'custom',
                title: 'Aduana',
                translate: 'Aduana',
                type: 'item',
                icon: 'location_city',
                url: 'customs/list',
            },
            {
                id: 'company',
                title: 'Compañía',
                translate: 'Compañía',
                type: 'item',
                icon: 'business',
                url: 'companies/list',
            },
            {
                id: 'news',
                title: 'Noticias',
                translate: 'Noticias',
                type: 'item',
                icon: 'new_releases',
                url: 'news/list',
            },
            {
                id: 'branch-offices',
                title: 'Sucursales',
                translate: 'Sucursales',
                type: 'item',
                icon: 'business',
                url: 'branch-offices/list',
            },

             {
                id: 'permits',
                title: 'Permisos',
                translate: 'Permisos',
                type: 'item',
                icon: 'lock',
                url: 'permits/list',
            },
            {
                id: 'export import',
                title: 'Exportadores e Importadores',
                translate: 'Exportadores e Importadores',
                type: 'item',
                icon: 'location_city',
                url: 'export-import/list',
            },
            {
                id: 'tariff',
                title: 'Tarifarios',
                translate: 'Tarifarios',
                type: 'item',
                icon: 'business',
                url: 'tariff/list',
            },
        ],
    },

    {
        id: 'report',
        title: 'Reportes',
        translate: 'Reportes',
        type: 'group',
        children: [
            {
                id: 'billing-table',
                title: 'Tabla Facturación',
                translate: 'Tabla Facturación',
                type: 'item',
                icon: 'list_alt',
                url: 'report/billing-table',
            },
            {
                id: 'billing-report',
                title: 'Reporte de Facturación',
                translate: 'Reporte de Facturación',
                type: 'item',
                icon: 'list_alt',
                url: 'report/billing-report',
            },
            {
                id: 'problem-report',
                title: 'Reporte de Problemas',
                translate: 'Reporte de Problemas',
                type: 'item',
                icon: 'list_alt',
                url: 'report/problem-report',
            },
            {
                id: 'follow-up-report',
                title: 'Reporte de FollowUp',
                translate: 'Reporte de FollowUp',
                type: 'item',
                icon: 'list_alt',
                url: 'report/follow-up-report',
            },
            {
                id: 'order-report',
                title: 'Reporte de Pedidos',
                translate: 'Reporte de Pedidos',
                type: 'item',
                icon: 'list_alt',
                url: 'report/order',
            },
            {
                id: 'order-load-report',
                title: 'Reporte de Ordenes Carga',
                translate: 'Reporte de Ordenes Carga',
                type: 'item',
                icon: 'list_alt',
                url: 'report/order-load-report',
            },
        ],
    },
];
